export default {
    '+ Add Horizontal Giude': '+ Добавить горизонтальное руководство',
    '+ Add Vertical Giude': '+ Добавить вертикальное руководство',
    '2D View': 'Плоский вид',
    '3D First Person': '3D от первого лица',
    '3D View': 'Объёмный вид',
    altitude: 'высота',
    Altitude: 'Высота',
    Areas: 'Поверхности',
    Cancel: 'Отмена',
    Catalog: 'Каталог',
    'Chain selected Elements to Group': 'Цепочка выбранных элементов в группу',
    Circular: 'круговой',
    color: 'цвет',
    'Configure layer': 'Настроить слой',
    Confirm: 'подтвердить',
    Copy: 'Копировать',
    'Delete group and all Elements': 'Удалить группу и все элементы',
    'Delete layer': 'Удалить слой',
    Delete: 'Удалить',
    'Elements on layer {0}': 'Элементов на слое {0}',
    Elements: 'элементы',
    'Get Screenshot': 'Сделать снимок экрана',
    'Group [{0}]': 'группа [{0}]',
    "Group's Barycenter": 'Барицентр группы',
    "Group's Elements": 'Элементы группы',
    Groups: 'группы',
    Guides: 'руководства',
    height: 'высота',
    Holes: 'Проёмы',
    Horizontal: 'горизонтальный',
    Items: 'Предметы',
    'Last Selected': 'Последний выбраный',
    'Layer config': 'Настройка слоя',
    Layer: 'Слой',
    Layers: 'Слои',
    Length: 'Длинна',
    Lines: 'Линии',
    'Load project': 'Загрузить проект',
    'Mouse X Coordinate': 'X-координата курсора',
    'Mouse Y Coordinate': 'Y-координата курсора',
    Name: 'Название',
    'New Empty Group': 'Новая пустая группа',
    'New Group from selected': 'Новая группа из выбранных',
    'New layer': 'Новый слой',
    'New project': 'Новый проект',
    opacity: 'непрозрачность',
    'Open catalog': 'Открыть каталог',
    order: 'порядок',
    Pan: 'Двигать',
    Paste: 'Вставить',
    'Project config': 'Настройка проекта',
    'Properties: [{0}] {1}': 'Свойства: [{0}] {1}',
    Prototype: 'Опытный образец',
    Reset: 'Сброс',
    Rotation: 'Вращение',
    'Save project': 'Сохранить проект',
    Save: 'Сохранить',
    'Scene Zoom Level': 'Уровень приближениия сцены',
    'Search Element': 'Поиск',
    'Snap to Grid': 'Привязка к сетке',
    'Snap to Guide': 'Привязать к руководству',
    'Snap to Line': 'Привязка к линии',
    'Snap to Point': 'Привязка к точке',
    'Snap to Segment': 'Привязка к сегменту',
    texture: 'текстура',
    thickness: 'толщина',
    'Toggle Group Visibility': 'Переключить видимость группы',
    "Un-chain all Group's Elements and remove Group":
        'Удалить цепочку элементов группы и удалить группу',
    'Un-chain Element from Group': 'Unchain Element из группы',
    'Undo (CTRL-Z)': 'Отменить действие (CTRL-Z)',
    Unselect: 'Снять выделене',
    Vertical: 'вертикальный',
    width: 'ширина',
    'Would you want to start a new Project?': 'Начать новый проект?',
    'Zoom in': 'Приблизить',
    'Zoom out': 'Отдалить',
};
