import React from 'react';
import ReactDOM from 'react-dom';
import Immutable, { Map } from 'immutable';
import immutableDevtools from 'immutable-devtools';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import MyCatalog from './catalog/mycatalog';
import {
    Models as PlannerModels,
    reducer as PlannerReducer,
    ReactPlanner,
    Plugins as PlannerPlugins,
} from 'react-planner';
import ContainerDimensions from 'react-container-dimensions';
import ReactPlannerWrapper from './components/ReactPlannerWrapper';

//define state
let AppState = Map({
    'react-planner': new PlannerModels.State(),
});

//define reducer
let reducer = (state, action) => {
    state = state || AppState;
    state = state.update('react-planner', (plannerState) =>
        PlannerReducer(plannerState, action)
    );
    return state;
};

let blackList =
    isProduction === true
        ? []
        : ['UPDATE_MOUSE_COORDS', 'UPDATE_ZOOM_SCALE', 'UPDATE_2D_CAMERA'];

if (!isProduction) {
    console.info(
        'Environment is in development and these actions will be blacklisted',
        blackList
    );
    console.info('Enable Chrome custom formatter for Immutable pretty print');
    immutableDevtools(Immutable);
}

//init store

// function logger({ getState }) {
//     return (next) => (action) => {
//         console.log('will dispatch', action);

//         // Call the next dispatch method in the middleware chain.
//         const returnValue = next(action);

//         // console.log('state after dispatch', getState());

//         // This will likely be the action itself, unless
//         // a middleware further in chain changed it.
//         return returnValue;
//     };
// }
// let store = createStore(reducer, null, applyMiddleware(logger));

let store = createStore(
    reducer,
    null,
    !isProduction && window.devToolsExtension
        ? window.devToolsExtension({
              features: {
                  pause: true, // start/pause recording of dispatched actions
                  lock: true, // lock/unlock dispatching actions and side effects
                  persist: true, // persist states on page reloading
                  export: true, // export history of actions in a file
                  import: 'custom', // import history of actions from a file
                  jump: true, // jump back and forth (time travelling)
                  skip: true, // skip (cancel) actions
                  reorder: true, // drag and drop actions in the history list
                  dispatch: true, // dispatch custom actions or action creators
                  test: true, // generate tests for the selected actions
              },
              actionsBlacklist: blackList,
              maxAge: 999999,
          })
        : (f) => f
);

let plugins = [
    PlannerPlugins.Keyboard(),
    PlannerPlugins.Autosave('react-planner_v0'),
    PlannerPlugins.ConsoleDebugger(),
];

let toolbarButtons = [];

ReactDOM.render(
    <Provider store={store}>
        <ContainerDimensions>
            {({ width, height }) => (
                <ReactPlannerWrapper
                    children={
                        <ReactPlanner
                            catalog={MyCatalog}
                            plugins={plugins}
                            width={width}
                            height={height}
                            toolbarButtons={toolbarButtons}
                            stateExtractor={(state) => state.get('react-planner')}
                        />
                    }
                />
            )}
        </ContainerDimensions>
    </Provider>,
    document.getElementById('app')
);
