// COLORS
export const COLORS = {
    white: '#FFF',
    black: '#000',
    blue: '#DBE9F6',
    light_blue: '#d0deef',
    lighter_blue: '#DEE8F5',
    medium_blue: '#566A7F',
    dark: '#333333',
};

export const MATERIAL_COLORS = {
    500: {
        amber: '#FFC107',
        blue_grey: '#607D8B',
        blue: '#2196F3',
        brown: '#795548',
        cyan: '#00BCD4',
        deep_orange: '#FF5722',
        deep_purple: '#673AB7',
        green: '#4CAF50',
        grey: '#9E9E9E',
        indigo: '#3F51B5',
        light_blue: '#03A9F4',
        light_green: '#8BC34A',
        lime: '#CDDC39',
        orange: '#FF9800',
        pink: '#E91E63',
        purple: '#9C27B0',
        red: '#F44336',
        teal: '#009688',
        yellow: '#FFEB3B',
    },
};

export const PRIMARY_COLOR = {
    main: '#ffffff',
    alt: '#2E2F33',
    icon: '#C2C2C2',
    border: '1px solid #555',
    text_main: '#28292D',
    text_alt: '#EBEBEB',
    input: '#55595C',
};

export const SECONDARY_COLOR = {
    main: '#1CA6FC',
    alt: '#005FAF',
    icon: '#1CA6FC',
    border: '1px solid #FFF',
};

export const MESH_SELECTED = '#005FAF';

export const AREA_MESH_COLOR = {
    selected: MESH_SELECTED,
    unselected: '#99C3FB',
    unselected2d: 'rgba(197, 204, 207, 0.5)'
};

export const LINE_MESH_COLOR = {
    selected: MESH_SELECTED,
    unselected: '#8E9BA2',
};
