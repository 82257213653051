import Autosave from './autosave';
import Keyboard from './keyboard';
import ConsoleDebugger from './console-debugger';

export {
    Autosave,
    Keyboard,
    ConsoleDebugger
};

export default {
    Autosave,
    Keyboard,
    ConsoleDebugger
};